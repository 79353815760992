import invert from "invert-color";
import type React from "react";
import { useMemo } from "react";
import type { Theme } from "shared/Board";
import { color, isDarkColor } from "shared/SharedUtils";
import { getFontSheetUrl } from "shared/Theme";
export type ThemeProviderProps<C extends React.ElementType = "div"> = React.ComponentPropsWithoutRef<C> & ThemeProviderOptions & {
  as?: C;
};
export interface ThemeProviderOptions {
  theme?: Theme;
  bodyBackground?: boolean;
}
export const useThemedProps = (options: ThemeProviderOptions) => {
  const {
    theme,
    bodyBackground
  } = options;
  const {
    primaryColor,
    darkColor,
    lightColor,
    backgroundColor,
    secondaryColor,
    accentColor,
    borderRadius,
    fontBody,
    dangerColor,
    warningColor,
    successColor,
    fontHeading
  } = theme || {};
  const vars = useMemo(() => {
    const isDarkBackground = isDarkColor(backgroundColor);
    return {
      "scrollbar-color": "var(--color-accent) transparent",
      "accent-color": "var(--color-accent)",
      "font-family": "var(--font-body-family)",
      //caretColor: `var(--color-secondary)`,
      "--color-muted-text": `color-mix(in srgb, ${primaryColor} 2%, #999)`,
      "--color-muted": `color-mix(in srgb, ${primaryColor} 2%, #f5f5f5)`,
      "--color-muted-foreground": "var(--color-dark)",
      "--color-muted-light": `color-mix(in srgb, ${primaryColor} 2%, #fafafa)`,
      "--color-input": "var(--color-muted-light)",
      "--color-ring": "var(--color-primary)",
      "--color-primary": primaryColor,
      "--color-primary-foreground": isDarkColor(primaryColor) ? "var(--color-light)" : "var(--color-dark)",
      "--color-secondary": secondaryColor,
      "--color-secondary-foreground": isDarkColor(secondaryColor) ? "var(--color-light)" : "var(--color-dark)",
      "--color-accent": accentColor,
      "--color-accent-foreground": isDarkColor(accentColor) ? "var(--color-light)" : "var(--color-dark)",
      "--color-danger": dangerColor,
      "--color-danger-foreground": isDarkColor(dangerColor) ? "var(--color-light)" : "var(--color-dark)",
      "--color-warning": warningColor,
      "--color-warning-foreground": isDarkColor(warningColor) ? "var(--color-light)" : "var(--color-dark)",
      "--color-success": successColor,
      "--color-success-foreground": isDarkColor(successColor) ? "var(--color-light)" : "var(--color-dark)",
      "--color-dark": darkColor,
      "--color-light": lightColor,
      "--color-background": backgroundColor,
      "--color-background-contrast": backgroundColor ? invert(backgroundColor) : undefined,
      "--color-foreground": isDarkBackground ? "var(--color-light)" : "var(--color-dark)",
      "--font-body-family": fontBody?.family ? `'${fontBody.family}', sans-serif` : undefined,
      "--font-body-weight": fontBody?.weight,
      "--font-heading-family": fontHeading?.family ? `'${fontHeading.family}', sans-serif` : undefined,
      "--font-heading-weight": fontHeading?.weight,
      "--length-radius": borderRadius ? `var(--length-radius-${borderRadius})` : undefined,
      "--color-border": `color-mix(in srgb, ${isDarkBackground ? lightColor : darkColor} 15%, ${backgroundColor})`,
      "--color-selection": "var(--color-accent)",
      "--color-selection-foreground": "var(--color-accent-foreground)"
    };
  }, [backgroundColor, lightColor, darkColor, primaryColor, secondaryColor, accentColor, borderRadius, fontBody?.family, fontBody?.weight, fontHeading?.family, fontHeading?.weight, bodyBackground]);
  return {
    //style: vars,
    "data-theme": theme?.id ?? theme?.name,
    children: <>
        {theme?.fontBody ? <link key={`gFonts-${theme.fontBody.family}`} data-font-sheet={theme.fontBody.family} href={getFontSheetUrl(theme.fontBody.family)} rel="stylesheet" /> : null}
        {theme?.fontHeading && theme?.fontBody?.family !== theme.fontHeading.family ? <link key={`gFonts-${theme.fontHeading.family}`} data-font-sheet={theme.fontHeading.family} href={getFontSheetUrl(theme.fontHeading.family)} rel="stylesheet" /> : null}

        {theme ? <>
            <style key={`theme-${theme.id ?? theme.name}`}>{`
          ${bodyBackground ? `body { background-color: ${backgroundColor}; }` : ""}
          
          [data-theme=\'${theme.id ?? theme.name}\'] {
            ${Object.entries(vars).map(([key, value]) => value !== undefined ? `${key}: ${value};` : "").join("\n")}
          }
        `}</style>
          </> : null}
      </>
  };
};
const ThemeProvider = <C extends React.ElementType = "div",>(props: ThemeProviderProps<C>) => {
  const {
    theme,
    as,
    children,
    bodyBackground,
    ...otherProps
  } = props;
  const themeProps = useThemedProps({
    theme,
    bodyBackground
  });
  const Tag = as ?? "div";
  return <Tag {...themeProps} {...otherProps} data-sentry-element="Tag" data-sentry-component="ThemeProvider" data-sentry-source-file="ThemeProvider.tsx">
      {themeProps.children}
      {children}
    </Tag>;
};
export default ThemeProvider;